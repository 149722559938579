import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { BrowserFrame } from "../components/BrowserFrame"
import { Unpublished } from "../components/Unpublished"
import ReactMarkdown from "react-markdown"
import { WorkItem } from "../components/WorkItem"
import { AboutTeaser } from "../components/AboutTeaser"

class WorkIndex extends React.Component<MHPageProps> {
  render() {
    const { data } = this.props
    const posts = data.allMdx.edges

    return (
      <>
        <SEO
          title="Stuff I've worked on"
          description="An overview of places I've worked, projects I've done, or side projects I've worked on around with."
        />

        <h1 className="n-text-centered n-vspacing-large">
          Web development projects
        </h1>

        <ul>
          {posts.map(({ node }, i) => (
            <WorkItem
              node={node}
              key={node.id}
              eager={i === 0}
              className="n-vspacing-large"
            />
          ))}
        </ul>

        <div className="oneColumn">
          <h2 className="n-text-centered n-spacing-top-large n-spacing-bottom-medium">
            About me
          </h2>

          <AboutTeaser contentFilter="sound" />
        </div>
      </>
    )
  }
}

export default WorkIndex

export const pageQuery = graphql`
  query ($publishedFilter: BooleanQueryOperatorInput) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        fields: { contentType: { eq: "work" } }
        frontmatter: { published: $publishedFilter }
      }
    ) {
      edges {
        node {
          ...MHWorkItem
        }
      }
    }
  }
`
